import { GreenhouseFormKeys } from "@/components/forms/";

// Fields we will always pre-render for the application form
export const FIXED_FIELDS_TEXT = [
	GreenhouseFormKeys.firstName,
	GreenhouseFormKeys.lastName,
	GreenhouseFormKeys.email,
	GreenhouseFormKeys.phone,
];
export const FIXED_FIELDS_FILES = [GreenhouseFormKeys.resume, GreenhouseFormKeys.coverLetter];

// Dynamic field types fetched from Greenhouse that we don't want to render
export const EXCLUDED_GREENHOUSE_FIELD_TYPES = ["input_file", "multi_value_multi_select"];

export const MAX_FILE_SIZE = 50_000_000;

// Used in "" package functionality
export const DROPZONE_CONFIG = {
	noClick: true,
	noKeyboard: true,
	maxFiles: 1,
	maxSize: MAX_FILE_SIZE,
	accept: ".pdf",
};
