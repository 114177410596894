import { useState, useEffect } from "react";

import {
	EXCLUDED_GREENHOUSE_FIELD_TYPES,
	FIXED_FIELDS_FILES,
	FIXED_FIELDS_TEXT,
} from "@/constants/greenhouse";
import { endpoints } from "@/endpoints";

export const useJobQuestions = (selectedJobId: string) => {
	const [jobQuestions, setJobQuestions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (!selectedJobId) return;

		const fetchJobData = async () => {
			setLoading(true);
			setError(null);
			try {
				// https://developers.greenhouse.io/job-board.html#retrieve-a-job
				const response = await fetch(
					endpoints.greenhouse.job.get(selectedJobId, false, true)
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const data = await response.json();
				let questions = data?.questions;

				// We want to filter out some of the questions as we want to handle
				// them manually e.g. any fields for files, and the standard fields
				if (questions?.length) {
					questions = questions.filter(
						item =>
							!item.fields.some(
								field =>
									EXCLUDED_GREENHOUSE_FIELD_TYPES.includes(field.type) ||
									[...FIXED_FIELDS_FILES, ...FIXED_FIELDS_TEXT].includes(
										field.name
									)
							)
					);
				}
				setJobQuestions(questions || []);
			} catch (error) {
				setError(error.message);
				setJobQuestions([]);
			} finally {
				setLoading(false);
			}
		};

		fetchJobData();
	}, [selectedJobId]);

	return { jobQuestions, loading, error };
};
